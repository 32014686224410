.my-briefcase {
  padding-right: 1px;
  .asi-big-title {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }
  .bri-right-section {
    background-color: #f7f7f7;
    font-weight: bold;
    padding: 3rem 3rem 5rem;
  }
  .no-record {
    color: #666;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
    position: absolute;
    top: 4.8rem;
  }
  .list-content {
    box-shadow: 0 0 1px #000000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      background-color: #fff;
      border-radius: 6px;
  }
  .noprofilepic {
    width: 10rem;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-area {
    padding: 0.5rem 2rem;
    border-radius: 7px;
    // width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #fff;
      font-weight: bold;
    }
  }
  .bookmark {
    width: 3rem;
    height: 3rem;
  }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #495057;
      font-size: 15px;
    }
    .ant-tabs-tab-btn {
      color: #999999;
      font-size: 15px;
    }
}
