.swiper-list-page {
  margin-top: 2rem;
  background: #f8f9fa;
  padding: 2rem;
  .recommend-contact-list {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
  }
  .list-content {
    background: #ffffff;
    padding: 2rem 2rem 1rem;
    min-width: 32rem;
    margin-bottom: 4rem;
  }
  .type {
    padding: 0.5rem 1rem;
    text-align: center;
    background: #019eaf;
    border-radius: 0.5rem;
    color: #ffffff;
    font-size: 1.2rem;
  }
  .user {
    color: white;
    width: 6rem;
    height: 6rem;
  }
  .arrow-left {
    object-fit: contain;
    margin-right: 3.6rem;
    width: 3rem;
    height: 3rem;
  }
  .arrow-right {
    object-fit: contain;
    margin-left: 3.6rem;
    width: 3rem;
    height: 3rem;
  }
  .username {
    font-size: 1.8rem;
  }
  .description {
    width: 12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contact-type {
    background: #6c757d;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
  @media (min-width: 1440px) {
    .username {
      font-size: 16px;
      font-weight: bold;
    }

    .user {
      color: white;
      width: 50px;
      height: 50px;
    }
  }
}

@media (min-width: 1280px) {
  .swiper-cell {
    margin-right: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .swiper-cell {
    margin-right: 6rem;
  }
}
