/* 关于我们弹框 */
.AboutUs {
  /* box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%); */
  padding: 32px;
  background-color: #ffffff;
  border-radius: 7px;
}
.logo{
    width: 25rem;
    height: 25rem;
    /* box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%); */
    border-radius: 2px;
    object-fit: contain;
  }
  .modalSectionHeader{
    color: #000000;
  }
  .companyProfileSection{
    margin-top: 2rem;
  }
    @media screen and (max-width: 768px) {
      .logo {
          width: 100%;
          height: auto;
          border-radius: 2px;
          object-fit: contain;
          margin-bottom: 16px;
        }
    }