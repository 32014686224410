.user-list {
  border: 1px solid #EEF1F3;
  td:last-child {
    padding-right: 0 !important;
    padding-left: 0 !important;
    min-width: 260px;
  }
    .organizer {
      width: 80px;
      color: #005009;
      border: 1px solid #005009;
      border-radius: 7px;
    }
  .exhibitor {
      width: 80px;
      color: #3DADEB;
      border: 1px solid #3DADEB;
      border-radius: 7px;
  }
    .visitor {
      width: 80px;
      color: #89C242;
      border: 1px solid #89C242;
      border-radius: 7px;
    }
    .yuan {
      border: 1px solid #B2BEC3;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
        .oddRow {
          background: #ffffff
        }
    
        .evenRow {
          background: #F7FAFC
        }
}