.card {
  margin-top: 4rem;
  .card-status-area {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
    padding: 0.5rem 1rem;
    background: #d9000d;
  }
  .card-status-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .card-product-title {
    font-family: "Poppins",sans-serif;
    font-size: 2.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    margin-top: 2rem;
    color: #000000;
  }
  .card-product-des {
    margin: 2rem 0.05rem 3rem 0;
    object-fit: contain;
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
  }
  .tag-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    word-wrap: break-word;
    word-break: break-all;
  }
  .card-card-bottom {
    display: flex;
    flex-direction: row;
    //align-items: center;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9.6rem;
  }
  .page-item {
    font-size: 1.9rem;
  }
  .next-btn {
    border-radius: 0.7rem;
    background-color: #d9000d;
    color: #ffffff;
    font-size: 1.9rem;
  }
  .ant-pagination-item-active {
    border-color: #ffffff;
  }
  .ant-pagination-item-active a {
    color: #d9000d;
  }
  .ant-pagination-item:hover {
    border-color: #d9000d;
  }
  .ant-pagination-item:hover a {
    color: #d9000d;
  }
  @media (min-width: 300px) and (max-width: 768px) {
    .card-list {
      min-height: 68.2rem;
      border-radius: 0.5rem;
      border: solid 0.05rem #8c8b8b;
      padding: 2rem 2rem;
      position: relative;
    }
    .card-product-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border: solid 0.05rem #8c8b8b;
    }
  }
  @media (min-width: 768px) {
    .card-list {
      max-width: 36.9rem;
      height: 68.2rem;
      border-radius: 0.5rem;
      border: solid 0.05rem #8c8b8b;
      padding: 2rem 2rem;
      position: relative;
    }
    .card-product-img {
      width: 32rem;
      height: 32rem;
      object-fit: cover;
      border: solid 0.05rem #8c8b8b;
    }
  }
}
