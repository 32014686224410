.recommendation-page {
  .list-card {
    padding: 2rem 2rem 1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    max-width: 360px;
  }
  .type {
    padding: 0 1rem;
    text-align: center;
    background: #019eaf;
    border-radius: 0.5rem;
    color: #ffffff;
  }
  .user {
    color: white;
    width: 6rem;
    height: 6rem;
  }
  .username {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .contact-type {
    padding: 0.5rem 1rem;
    background: #6c757d;
    border-radius: 2rem;
  }
  .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (min-width: 1440px) {
    .username {
      font-size: 16px;
      font-weight: bold;
    }
    .user {
      color: white;
      width: 50px;
      height: 50px;
    }
  }
}
