.tags-area {
  margin-top: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tags-show-less {
  height: 5rem;
  overflow: hidden;
}

.tag-view {
  padding: 0.6rem 1.5rem;
  margin-bottom: 1.8rem;
}

.tags-text {
  font-family: "Poppins",sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #5d5d5d;
}

.show-btn {
  height: 2.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

.show-text {
  min-width: 9.8rem;
  font-family: "Poppins",sans-serif;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #d9000d;
}

.arrow-up-img {
  object-fit: contain;
  margin-left: 1.6rem;
}
