#components-layout-demo-fixed .logo {
  float: left;
  width: 12rem;
  height: 3.1rem;
  margin: 1.6rem 2.4rem 1.6rem 0;
  background: rgba(255, 255, 255, 0.2);
}
.breadcrumb {
  font-size: 1.6rem;
}
.content {
  background: #fff;
  margin-top: 1.9rem;
}
.search-page {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-img {
  width: 36.2rem;
  object-fit: contain;
}
