.social-list {
  margin-top: 2rem;
  .list-content {
    border: 1px solid #dee2e6 !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 2rem;
    overflow: hidden;
    min-height: 40rem;
  }
  .social-title {
    width: 100%;
    text-align: left;
  }
  .social-img {
    width: 25rem;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status {
    margin-top: 1rem;
    font-size: 1.8rem;
  }
  .open {
    color: green;
  }
  .closed {
    color: red;
  }
  .disabled {
    cursor: not-allowed;
  }
  .img {
    margin-top: 1rem;
    position: relative;
  }
  .icon {
    position: absolute;
    width: 40%;
    background: white;
  }
  .item-img {
    //width: 100%;
    //height: 100%;
  }
  .pic-4 {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 1.5rem;
    left: 10.5rem;
  }
  .pic-6 {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 1rem;
    left: 7rem;
  }
  .pic-8 {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    left: 8rem;
  }
  .pic-10 {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0.6rem;
    left: 6.7rem;
  }
  .pic-20 {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0.6rem;
    left: 3rem;
  }
}