.AgItemSpeakerList {
  // margin-top: 2rem;

  .profilePic {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f2f5f7;
    vertical-align: middle;
    // cursor: pointer;
  }

  .speakerName {
    // color: #004096;
    font-weight: bold;
    font-size: 16px;
    // margin-top: 2rem;
    // cursor: pointer;
  }

  .speakerJobTitle {
    font-size: 12px;
    font-weight: 400;
    color: #2D3436;
    line-height: 1.8em;
    line-height: 14px;
    letter-spacing: 0.03em;
    // cursor: pointer;
  }
}