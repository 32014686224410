.contact-list {
  .infinite-container {
    overflow: auto;
    border-radius: 0.4rem;
    width: 100%;
  }
  .loading-container {
    position: absolute;
    bottom: 4rem;
    width: 100%;
    text-align: center;
  }
  .list-header {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    background-color: #e7e7e7;
    color: #212529;
    // opacity: .5;
    border-radius: 0.5rem;
  }
  .row {
    padding: 2rem 8rem 2rem 2rem;
    // margin-bottom: 3rem;
    // border: 0.1rem solid #dee2e6;
    background-color: #ffffff;
    border-radius: 0.5rem;
    position: relative;
    .rate {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .row-title {
    font-size: 1.9rem;
    font-weight: bold;
  }
  .type {
    padding: 0 1rem;
    color: #ffffff;
  }
  .user {
    color: white;
    // width: 6rem;
    // height: 6rem;
    margin-top: 1rem;
    width: 100%;
  }
  .contact-type {
    background: #d9000d;
    border-radius: 0.5rem;
    font-weight: bold;
  }
  .company-name {
    color: #57606a;
    font-size: 1.9rem;
  }
  .btn {
    font-size: 2rem;
    font-weight: 700;
    padding: 1rem 2.5rem;
    border-radius: 5px;
    border: 1px solid transparent;
    img {
      width: 2.7rem;
    }
  }
  .mT30 {
    margin-top: 3rem;
  }
}
