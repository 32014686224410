.JoinSessionTabPane {
  // height: 40.8rem;
  height: 52rem;
  min-height: 55vh;
  overflow-y: scroll;

  // padding-right: 2rem;
  .secction-item {
    padding: 1.6rem 2rem 0;
    margin: 0 2rem 0rem;
    // border-radius: 5px;
    // background-color: #fff;
    // // width: 100%;
    // -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    //   0 2px 5px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 5px rgba(0, 0, 0, 0.3);
    // // box-shadow: 0 0 2px rgb(0 0 0);
    // border-right: 0.8rem solid transparent;
    display: block;
    cursor: pointer;
    margin-top: 10px;

    .Catchup {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 10px;
      width: 79px;
      height: 24px;
      background: #019eaf;
      border-radius: 7px;
    }

    .time {
      font-size: 2rem;
      line-height: 1.5;
      color: #019eaf;
      // font-weight: 700;
      margin-left: 2rem;
    }
  }

  .secction-item-current {
    // background-color: rgb(255, 254, 254);
    // color: #fff;
    // background-color: aliceblue;
  }
  .title-name {
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
  }
  @media screen and (max-width: 767px) {
    .secction-item {
      margin-top: 32px;
    }
    .secction-item:nth-child(1) {
      margin-top: 16px;
    }
  }
}
