.social-media-page {
  .list-item {
    width: 16rem;
    height: 14.2rem;
  }
  .icon {
    width: 6rem;
    height: 6rem;
  }
  .display_title {
    width: 12rem;
    text-align: center;
    word-wrap: break-word;
  }
}
