.chat-modal {
  .user-area {
    width: 8rem;
    height: 8rem;
    border-radius: 4rem;

    .user {
      color: white;
      width: 8rem;
      height: 8rem;
    }
  }

  .name {
    margin-left: 2rem;
  }
}
