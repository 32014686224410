.expo-hall {
  position: relative;

  .bgImg {
    width: 100%;
  }
  .search {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .btn {
    margin-left: 1rem;
    border-color: #019eaf;
    color: #019eaf;
    border-radius: 1rem;
  }

  @media (min-width: 768px) {
    .btn-list {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: rgba(217, 217, 217, 0.2);
      width: 100%;
      margin-top: -10rem;
      height: 8rem;
      z-index: 9999;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 768px) {
    .btn-list {
      position: relative;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: rgba(217, 217, 217, 0.2);
      width: 100%;
      height: auto;
      padding-bottom: 3rem;
      margin-top: 8px;
      margin-bottom: 0;
      .col-btn {
        margin-top: 3rem;
      }
    }
  }
  .btn-area {
    padding: 0.5rem 2rem;
    // margin-top: 3rem;
    margin-right: 2rem;
    border-radius: 2rem;
    span {
      // font-size: 1.8rem;
      // font-weight: bold;
      color: #ffffff;
    }
  }
  .btn-area:hover {
    background-color: #ffffff;
    span {
      color: #019eaf;
    }
  }
  .active {
    background: #019eaf;
    span {
      // font-size: 1.8rem;
      color: white;
    }
  }
  .banner {
    width: 100%;
    height: 100%;
  }
}
