.room {
  width: 25%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  //background: red;
  z-index: 9999;
  .close {
    z-index: 99999;
    color: red;
    position: fixed;
    top: .2rem;
    right: 10%;
    font-size: 1.8rem;
  }
}