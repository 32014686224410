.my-networking-page {
  background-color: #ffffff;
  padding: 2rem 0;
  border-radius: 15px;
  .btn {
    padding: 0.5rem 2rem;
    border: 0.1rem solid #019eaf;
    border-radius: 2rem;
    span {
      font-size: 16px;
      color: #019eaf;
    }
  }
}
