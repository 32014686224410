.speaker-detail {
  padding-bottom: 2rem;
  .content {
    padding: 32px;
    border-radius: 15px;
  }

  .profilePic {
    width: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f2f5f7;
    vertical-align: middle;
    cursor: pointer;
  }

  .speakerName {
    color: #004096;
    font-weight: bold;
    font-size: 3.5rem;
    margin-top: 2rem;
    cursor: pointer;
    margin: 4rem 0;
  }

  .speakerJobTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  .speaker_description {
    color: #4b4f58;
    padding-bottom: 15rem;
  }

  .ant-col-xs-24 {
    // text-align: center;
  }
  .backBtn {
    position: fixed;
    right: 40px;
    top: 20px;
  }

  @media (min-width: 768px) {
    .ant-col-md-10 {
      text-align: left;
    }
  }
    @media (max-width: 300px) {
      .profilePic {
          width: 100%;
          width: 30rem;
          height: 30rem;
          object-fit: cover;
          border-radius: 50%;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #f2f5f7;
          vertical-align: middle;
          cursor: pointer;
        }
    }
}