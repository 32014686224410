.product-area {
  .search-title {
    font-family: "Poppins",sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
    margin-top: 1.4rem;
  }
  .ant-input-wrapper {
    border-radius: 0.5rem;
    border: solid 0.1rem #d9000d;
    background-color: #ffffff;
  }

  .ant-input {
    height: 5rem;
    font-family: "Poppins",sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
  }

  .ant-input-group-addon button {
    width: 8.4rem;
    height: 5rem;
    border-width: 0;
    border-left-color: #707070;
  }

  .product-card {
    margin-top: 2.2rem;
  }

  .anticon-search {
    font-size: 2.7rem;
    color: #d9000d;
  }

  .search {
    position: relative;
  }

  .search-line {
    width: 0.1rem;
    height: 2.4rem;
    background: #707070;
    object-fit: contain;
    position: absolute;
    right: 8.4rem;
    top: 1.3rem;
    z-index: 100;
  }

  .status-area {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 1;
    padding: 0.5rem 1rem;
    background: #d9000d;
  }

  .status-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .product-title {
    object-fit: contain;
    font-family: "Poppins",sans-serif;
    font-size: 2.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .product-des {
    font-family: "Poppins",sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 1rem;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 14rem;
  }

  .ant-pagination-item-active {
    border-color: #ffffff;
  }

  .ant-pagination-item-active a {
    color: #d9000d;
  }

  .ant-pagination-item:hover {
    border-color: #d9000d;
  }

  .ant-pagination-item:hover a {
    color: #d9000d;
  }

  @media (min-width: 300px) and (max-width: 768px) {
    .product-list {
      min-height: 41.9rem;
      padding: 1.5rem 1rem 2.5rem 1.6rem;
      object-fit: contain;
      border-radius: 0.5rem;
      border: solid 0.05rem #8c8b8b;
      position: relative;
    }
    .product-img {
      width: 100%;
      height: auto;
      margin: 0 0.1rem 2rem 0;
      object-fit: contain;
      border: solid 0.05rem #8c8b8b;
    }
  }
  @media (min-width: 768px) {
    .product-list {
      max-width: 27.4rem;
      height: 41.9rem;
      padding: 1.5rem 1rem 2.5rem 1.6rem;
      object-fit: contain;
      border-radius: 0.5rem;
      border: solid 0.05rem #8c8b8b;
      position: relative;
    }
    .product-img {
      width: 24.8rem;
      height: 24.8rem;
      margin: 0 0.1rem 2rem 0;
      object-fit: contain;
      border: solid 0.05rem #8c8b8b;
    }
  }
}
