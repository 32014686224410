.filter-check {
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 10px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 3px;
  }

  margin: 1.5rem 1.5rem 0 0;
  padding: 3rem;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  .ant-btn {
    // color: #fff;
    // background-color: #343a40;
    // border-color: #343a40;
    // box-shadow: none;
    color: #019eaf;
    background: #ebfafa;
    border-color: #ebfafa;
    box-shadow: none;
    margin-bottom: 92px;
  }

  .filter-t {
    font-size: 2.5rem;
    font-weight: bold;
    color: #212529;
    line-height: 2em;
    margin-top: 1em;
  }
  .filter-title {
    cursor: pointer;
    font-weight: 500;
    padding: 1rem 0;
    vertical-align: middle;
    word-spacing: 1rem;
    font-size: 14px;
    color: #636e72;
  }

  .mL05 {
    margin-left: 0.5rem;
  }

  .a-right {
    text-align: right;
  }
}
