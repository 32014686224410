.product-detail-modal {
  height: 68rem;
  overflow: scroll;
  padding: 0 1.5rem;
  .slider-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .slider {
    width: 75%;
    position: relative;
  }
  .product-img {
    width: 75%;
    height: auto;
  }
  .arrow-left {
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 99;
  }
  .arrow-right {
    position: absolute;
    top: 50%;
    right: 1rem;
    z-index: 99;
  }
  .product-text {
    .detail-title {
      font-size: 2.8rem;
      font-weight: bold;
      margin-top: 1rem;
    }
    .detail-des1 {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .detail-des2 {
      font-size: 1.6rem;
      margin-top: 1rem;
    }
    .detail-list {
      margin-top: 1rem;
      margin-left: 2rem;
    }
    li:before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      display: inline-block;
      border-radius: 50%;
      background: #000000;
      vertical-align: middle;
      margin-right: 1.4rem;
    }
  }
}
