.schedule-meeting-modal {
  border-radius: 7px;

  .dropdown {
    width: 272px;
    padding: 1rem 2rem;
    border-radius: 7px;
    border: 1px solid #DFE6E9;
    display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .dropdown-menu {
    height: 100px;
      overflow-y: scroll;
  }
}