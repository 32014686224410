/* SpeicalOffers */
.SpeicalOffers {
    font-weight: 900;
    font-size: 1.7em;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 2rem;
}
 .ContentArea {
   background-color: #DFE6E9;
 }