.ChatEngine-contenter {
  --sendbird-light-primary-500: #d5e4f5;
  --sendbird-light-primary-400: #0063F7;
  --sendbird-light-primary-300: #0063F7; 
  --sendbird-light-primary-200: #c2a9fa;
  --sendbird-light-primary-100: #dbd1ff;

  --sendbird-light-secondary-500: #066858;
  --sendbird-light-secondary-400: #027d69;
  --sendbird-light-secondary-300: #259c72;
  --sendbird-light-secondary-200: #69c085;
  --sendbird-light-secondary-100: #a8e2ab;

  --sendbird-light-information-100: #adc9ff;
  --sendbird-highlight-100: #fff2b6;

  --sendbird-light-error-500: #9d091e;
  --sendbird-light-error-400: #bf0711;
  --sendbird-light-error-300: #de360b;
  --sendbird-light-error-200: #f66161;
  --sendbird-light-error-100: #fdaaaa;

  --sendbird-light-background-700: #000000;
  --sendbird-light-background-600: #161616;
  --sendbird-light-background-500: #2C2C2C;
  --sendbird-light-background-400: #393939;
  --sendbird-light-background-300: #bdbdbd;
  --sendbird-light-background-200: #e0e0e0;
  --sendbird-light-background-100: #eeeeee;
  --sendbird-light-background-50:  #FFFFFF;

  --sendbird-light-overlay-01: rgba(0, 0, 0, 0.55);
  --sendbird-light-overlay-02: rgba(0, 0, 0, 0.32);

  --sendbird-light-onlight-01: rgba(0, 0, 0, 0.88);
  --sendbird-light-onlight-02: rgba(0, 0, 0, 0.50);
  --sendbird-light-onlight-03: rgba(0, 0, 0, 0.38);
  --sendbird-light-onlight-04: rgba(0, 0, 0, 0.12);

  --sendbird-light-ondark-01: rgba(255, 255, 255, 0.88);
  --sendbird-light-ondark-02: rgba(255, 255, 255, 0.50);
  --sendbird-light-ondark-03: rgba(255, 255, 255, 0.38);
  --sendbird-light-ondark-04: rgba(255, 255, 255, 0.12);

  --sendbird-light-shadow-01: 0 1px 5px 0 rgba(33, 34, 66, 0.04), 0 0 3px 0 rgba(0, 0, 0, 0.08), 0 2px 1px 0 rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-02: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-03: 0 6px 10px -5px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.08), 0 16px 24px 2px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-04: 0 9px 15px -7px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12);

  --sendbird-light-shadow-05: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 6px 0 rgba(0, 0, 0, 0.12);

  --sendbird-light-shadow-message-input: 0 1px 5px 0 rgba(33, 34, 66, 0.12), 0 0 1px 0 rgba(33, 34, 66, 0.16), 0 2px 1px 0 rgba(33, 34, 66, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
