.footer {
  padding: 2.5rem 8rem;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 999;

  .footer-text {
    font-family: "Poppins",sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    // text-align: center;
    // color: #212121;
    font-weight: bold;
  }
  .footer-right-area {
    display: flex;
    justify-content: flex-end;
  }
  .copyrights {
    display: flex;
    justify-content: flex-start;
  }
    @media (max-width: 768px) {
      .footer-left-area {
        display: flex;
        justify-content: center;
      }
      .footer-right-area {
        display: flex;
        justify-content: center;
      }
      .copyrights {
        display: flex;
        justify-content: center;
      }
    }
}
