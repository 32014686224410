.header-user {
  .organizer {
      width: 80px;
      color: #005009;
      border: 1px solid #005009;
      border-radius: 7px;
      font-size: 12px;
    }
  
    .exhibitor {
      width: 80px;
      color: #3DADEB;
      border: 1px solid #3DADEB;
      border-radius: 7px;
      font-size: 12px;
    }
  
    .visitor {
      width: 80px;
      color: #89C242;
      border: 1px solid #89C242;
      border-radius: 7px;
      font-size: 12px;
    }

    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12),
      0 6px 16px 0 rgba(0, 0, 0, .08),
      0 9px 28px 8px rgba(0, 0, 0, .05)
}