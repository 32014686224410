.ChatEngine-contenter .sendbird-openchannel-user-message__right__bottom__message{

    background-color: var(--sendbird-light-background-100);
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 8px 12px;
    border-radius: 16px;
    white-space: pre-line;
    word-break: break-word;
}
.ChatEngine-contenter .sendbird-chat-header .sendbird-chat-header_left_title {
    margin-left: 8px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    max-width: 380px;
}   
.ChatEngine-contenter .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll_container .sendbird-openchannel-conversation-scrollcontainer_item-container{
    overflow: hidden;
}
.ChatEngine-contenter .sendbird-theme--light .sendbird-app_wrap .sendbird-app_channellist-wrap {
   
    font-size: 12px;
    color: gainsboro;
}
.ChatEngine-contenter .sendbird-openchannel-conversation-scroll {
    max-height: 536px;
}
.ChatEngine-contenter {
    left: 35%;
}
.ChatEngine-contenter .sendbird-chat-header .sendbird-chat-header_right_info {
    margin-left: 16px;
    min-width: 150px;
    font-size: 12px;
}
.ChatEngine-contenter .sendbird-theme--light .sendbird-message-input__edit {
    background-color: #d5e4f5;
}
.ChatEngine-contenter .sendbird-channel-list {
    padding-bottom: 130px;
}
.ChatEngine-contenter .sendbird-openchannel-conversation {
    border: 1px solid var(--sendbird-light-onlight-04);
}
.ChatEngine-contenter .closeOutlined {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
}
.ChatEngine-contenter .sendbird-conversation__messages {
    overflow-y: hidden;
    overflow-x: hidden;
    flex: 1 1;
    order: 2;
}
.ChatEngine-contenter .sendbird-conversation_messages .sendbird-conversation_messages-padding {
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}
.ChatEngine-contenter .MuiAvatar-root {
    font-size: 14px;
}
 .sendbird-modal {
    z-index: 999 !important;
}

@media (max-width: 800px){
.ChatEngine-contenter {
    width: 100%;
    left: 0;
    bottom: 0;
    max-height: 75vw;
    min-height: 75vh;
}}
