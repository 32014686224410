.product-page {
  // overflow: scroll;
  .list-content {
    box-shadow: 0 0 1px #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50rem;
    padding-bottom: 2rem;
    border-radius: 16px;
    padding: 2rem;
    background-color: #ffffff;
    // width: 35.3rem;
  }
  .product-img {
    width: 100%;
    height: 20rem;
    min-height: 20rem;
    max-height: 20rem;
    object-fit: contain;
    background-color: #F7FAFC;
  }
  .product-content {
    padding-top: 2rem;
    max-width: 30rem;
    overflow: hidden;
  }
}
