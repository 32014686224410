/* 样式重置 */
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1199px) {
  header.ant-layout-header.hp-header-horizontal .ant-menu.ant-menu-root {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  header.ant-layout-header.hp-header-horizontal {
    margin: 0;
  }
}
body {
  font-family: 'Poppins', sans-serif;
  /* color: #57606a !important; */
  -webkit-text-size-adjust: 100%;
  background: #f7f7f7 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #172c45;
  text-decoration: none;
}

a:hover {
  color: #cd0200;
  /*text-decoration: underline;*/
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  word-wrap: break-word;
}

.fw700 {
  font-weight: 700;
}
.content-view {
  position: relative;
  background: #fff;
  padding: 2rem 18.4rem 20rem;
}
.search-btn {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  position: absolute;
  bottom: 100px;
  right: 5rem;
  background: #cd0200;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
}
.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.bgColor {
  background-color: #019eaf;
}
.fColor {
  color: #019eaf;
}
.bColor {
  border-color: #019eaf !important;
}
.bgWhite {
  background-color: #fff;
}
.f12 {
  font-size: 1.2rem;
}
.f15 {
  font-size: 1.5rem;
}
.f16 {
  font-size: 1.6rem;
}
.f17 {
  font-size: 1.7rem;
}
.f18 {
  font-size: 1.8rem;
}
.f19 {
  font-size: 1.9rem;
}
.f20 {
  font-size: 2rem;
}
.f21 {
  font-size: 2.1rem;
}
.f22 {
  font-size: 2.2rem;
}
.f23 {
  font-size: 2.3rem;
}
.f24 {
  font-size: 2.4rem;
}
.f25 {
  font-size: 2.5rem;
}
.f30 {
  font-size: 3rem;
}
.pT5 {
  padding-top: 0.5rem;
}
.pT10 {
  padding-top: 1rem;
}
.pT20 {
  padding-top: 2rem;
}
.pT40 {
  padding-top: 4rem;
}
.pB5 {
  padding-bottom: 0.5rem;
}
.pB10 {
  padding-bottom: 1rem;
}
.pB20 {
  padding-bottom: 2rem;
}
.pL10 {
  padding-left: 1rem;
}
.pL20 {
  padding-left: 2rem;
}
.pR10 {
  padding-right: 1rem;
}
.pR20 {
  padding-right: 2rem;
}
.mL10 {
  margin-left: 1rem;
}
.mL20 {
  margin-left: 2rem;
}
.mR10 {
  margin-right: 1rem;
}
.mR20 {
  margin-right: 2rem;
}
.mB10 {
  margin-bottom: 1rem;
}
.mB20 {
  margin-bottom: 2rem;
}
/*盒模型*/

.flex {
  display: flex;
  flex-wrap: wrap;
}

/* 纵向排列 */

.flex_d_c {
  flex-direction: column;
}

/* 横向居中对齐 */

.flex_j_c {
  justify-content: center;
}

/* 自适应间距 */

.flex_sx {
  justify-content: space-around;
}

/* 横向左右对齐 */

.flex_rl {
  justify-content: space-between;
}

/*横向 左对齐 */

.flex_j_l {
  justify-content: flex-start;
}

/* 横向右对齐 */

.flex_j_r {
  justify-content: flex-end;
}

/* 纵向居中对齐 */

.flex_a_c {
  align-items: center;
}

/* 纵向向下对齐 */

.flex_a_e {
  align-items: flex-end;
}

/* felx 盒子中的项目 占据宽度 各占一半 */

.flex_1 {
  flex: 1;
}
.relative {
  position: relative;
}
/* 鼠标小手 */
.pointer {
  cursor: pointer;
}
/* 禁止换行 */

.space {
  white-space: nowrap;

  overflow: hidden;

  word-break: break-all;

  text-overflow: ellipsis;
}
/* 全局修改antmodal的弹框头部样式 */
/* div.ant-modal-header {
  background-color: #019EAF;
  padding: 0;
} */
/* div.ant-modal-title {
  color: #fff;
} */
/* div.ant-modal-footer {
  border-width: 0;
} */
/* .ant-modal-close-icon{
  box-shadow: 0 1px 0 #fff;
} */

/*盒模型居中对齐*/
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*盒模型居中对齐*/
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*z字体加粗*/
.bold {
  font-weight: bold;
}
.lighter {
  font-weight: lighter;
}
.pro-btn {
  background-color: #019eaf !important;
  border-color: #019eaf !important;
  color: #fff !important;
}
/*span显示2行以省略号结尾*/
.span {
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-center {
  text-align: center;
}
/* 大屏下字体16px */
@media (min-width: 768px) {
  .f768 {
    font-size: 16px;
  }
}
.page-container {
  /* max-width: 1140px; */
  padding-top: 2rem;
  padding-bottom: 30rem;
}
@media (min-width: 300px) and (max-width: 1280px) {
  /* 全局样式 */
  .center {
    /* text-align: center; */
    margin-left: 10rem;
    margin-right: 10rem;
  }
  /*标题字号*/
  .title {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
}
@media (min-width: 1280px) {
  /* 全局样式 */
  .center {
    /* text-align: center;
    margin-left: auto;
    margin-right: auto; */
    margin-left: 21rem;
    margin-right: 21rem;
  }
  /*标题字号*/
  .title {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
}
@media screen and (max-width: 767px) {
  .page-container {
    padding-top: 0;
  }
  .center {
    margin-left: 16px;
    margin-right: 16px;
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
