.product_detail_page {
  .detail {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  .margin-title {
    margin-top: 0.8rem;
  }
  .arrow-right-img {
    object-fit: contain;
    margin-left: 1.6rem;
  }
  .margin-top {
    margin-top: 2rem;
  }
  .title-value {
    margin-top: 1.5rem;
    font-family: "Poppins",sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    text-align: left;
    color: #000000;
  }
  .title-key {
    margin-top: 1.5rem;
    font-family: "Poppins",sans-serif;
    font-size: 1.8rem;
    line-height: 1.17;
    text-align: left;
    color: #5d5d5d;
  }
  .top-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: red;
  }
  .common {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .start {
    margin-top: 2.7rem;
    margin-bottom: 2rem;
  }
  .second {
    margin-left: 7rem;
  }
  .chat-text {
    font-family: "Poppins",sans-serif;
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
  .detail-chat-btn {
    padding: 1.2rem 0 1rem 0;
    object-fit: contain;
    border-radius: 0.8rem;
    background-color: #d9000d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 2.2rem;
  }
  .detail-meet-btn {
    padding: 1.2rem 0 1rem 0;
    object-fit: contain;
    border-radius: 0.8rem;
    border: solid 0.1rem #d9000d;
    background-color: #ffffff;
    justify-content: center;
  }
  .detail-right {
    display: flex;
    flex-direction: column;
  }
  .sponsored {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.4rem;
    object-fit: contain;
    margin-top: 0.6rem;
    background: #00862f;
    padding: 1rem 2rem;
  }
  .sponsored-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .meet-text {
    font-family: "Poppins",sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #d9000d;
  }
  .line {
    height: 0.05rem;
    margin-top: 2.8rem;
    background: #8c8b8b;
  }
  .des-text {
    font-family: "Poppins",sans-serif;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 2rem;
  }
  .des-value {
    object-fit: contain;
    font-family: "Poppins",sans-serif;
    font-size: 1.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
    margin-top: 2rem;
  }
  .product-btn-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
  }
  .product-img-list {
    margin-top: 2rem;
  }
  .img-list-area {
    max-width: 30rem;
    background: red;
    display: flex;
    flex-direction: row;
  }
  .more-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .divider {
    width: 1.6rem;
  }
  .show-less {
    max-height: 15rem;
    overflow: hidden;
  }
  .img-arrow-left {
    margin-right: 3rem;
  }
  .img-arrow-right {
    margin-left: 3rem;
  }
  .selected-img {
    border: solid 0.1rem #d9000d;
  }
  .favourite-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: 1.3rem;
  }
  @media (min-width: 300px) and (max-width: 768px) {
    .product-logo {
      width: 100%;
      height: auto;
      object-fit: contain;
      border: solid 0.1rem #8c8b8b;
    }
    .btn-img {
      object-fit: contain;
      width: 5rem;
      height: 4.4rem;
    }
    .right-btn-img {
      margin: 0.2rem 1.1rem 0 0;
      object-fit: contain;
      width: 5.2rem;
      height: 5.2rem;
    }
  }
  @media (min-width: 768px) {
    .product-logo {
      width: 100%;
      max-width: 56.3rem;
      height: auto;
      object-fit: contain;
      border: solid 0.1rem #8c8b8b;
    }
    .btn-img {
      object-fit: contain;
      width: 2.5rem;
      height: 2.2rem;
    }
    .right-btn-img {
      margin: 0.2rem 1.1rem 0 0;
      object-fit: contain;
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}
