.JoinDetailSlider {
  // margin-top: 2rem;
  // padding-left: 3rem;
  background: #fff;
  height: 100%;
  min-height: 100%;
  // overflow-y: scroll;
  order: 1px solid #dfe6e9;
  border-radius: 7px;
  .ant-tabs-tab .anticon {
    margin-right: 0px;
  }
  .ant-tabs > .ant-tabs-nav {
    // background-color: #005c9b;
    // color: #fff;
    padding-left: 2rem;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    cursor: pointer;
    color: #2d3436;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img {
    color: #019eaf;
    filter: drop-shadow(#019eaf 10000px 0);
    transform: translateX(-10000px);
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 50px;
  }
}
