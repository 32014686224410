.meeting-detail-modal {
  .content-area {
    background-color: #ffffff;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 7px;
  }

  .action-title {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
  }

  .input-area {
    margin-top: 8rem;
  }
}