.contact-page {
    .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
        left: 10px;
      }
    
      .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
        border-radius: 3px;
      }
    .search-box {
        display: flex;
        justify-content: end;
    }

    .ant-btn > span {
        white-space:pre;
    }

    .letters {
        border-radius: 5px;
        position: relative;
        .flex-item {
            flex: 1;
            text-align: center;
            position: relative;
        }
    }
    .filter-title {
        color: #b4b4b4;
        font-size: 1.6rem;
    }
    .letterSelected {
        color: #212529;
        font-weight: bold;
        // transform: scale(1.2, 1.2);
        // display: inline-block;
        // font-size: 1.8rem;
    }
    .line {
        position: absolute;
        background-color: #212529;
        width: 4%;
        width: calc(100% / 27);
        height: 3px;
        bottom: 0;
        left: 0;
        transition: all 0.3s;
    }
}