.search-condition-area {
  .search-area-list {
    min-height: 50rem;
    overflow-y: scroll;
  }
  .tree_title {
    font-family: "Poppins",sans-serif;
    font-size: 1.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #d9000d;
  }
  .tree_title_in {
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
  }
  .img-arrow-down {
    width: 1.6rem;
    height: 1rem;
  }

  .search-area-top-line {
    height: 0.1rem;
    margin-top: 1.2rem;
    margin-bottom: 0.7rem;
    object-fit: contain;
    background-color: #d9000d;
    width: 100%;
  }

  .item-row-top {
    display: flex;
    flex-direction: row;
    margin: 0.8rem 1.2rem 0.8rem 0;
  }

  .item-row-text {
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
    margin-left: 1.2rem;
  }

  .item-row-line {
    height: 0.05rem;
    background: #8c8b8b;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9;
  }

  .ant-tree-checkbox {
    .ant-tree-checkbox-inner:hover {
      border-color: #d9000d;
    }
  }

  .ant-tree-checkbox-inner::after {
    background: #d9000d;
  }

  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background: #d9000d;
      border-color: #d9000d;
    }
  }

  .ant-tree-switcher {
    color: #d9000d;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .search-condition-area {
    padding: 1.85rem 2rem 0.05rem 2rem;
    object-fit: contain;
    border-radius: 0.5rem;
    border: solid 0.1rem #b1b1b1;
    margin-bottom: 2rem;
    width: 36.2rem;
    .item-cell {
      width: 28rem;
    }
  }
}

@media (min-width: 768px) {
  .search-condition-area {
    padding: 1.85rem 2rem 0.05rem 2rem;
    object-fit: contain;
    border-radius: 0.5rem;
    border: solid 0.1rem #b1b1b1;
    margin-bottom: 2rem;
    width: 36.2rem;
    .item-cell {
      width: 28rem;
    }
  }
}
