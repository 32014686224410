.JoinDetailDemand {
  // padding-bottom: 30rem;
  // height: 80vh;
  .name-title {
    color: #004096;
    font-weight: bold;
    font-size: 26px;
    padding: 2rem 0;
  }
  .video {
    width: 100%;
    // height: 50rem;
    height: 100%;
  }

  .banner-img-box {
    // padding: 1rem;
    padding-top: 1rem;
  }

  .banner-img{
    width: 100%;
  }
  .banner-img-box:nth-child(n+2) {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 767px) { 
  .JoinDetailDemand {
    padding-left: 16px;
    padding-right: 16px;
    .pL20 {
      padding-left: 0;
    }
    .video {
      width: 100%;
      height: 340px;
    }
  }
}