.Topic {
  .topic-type {
    // color: #6c757d !important;
    // font-size: 5rem;
    // line-height: 3em;
    font-weight: bolder;
    font-size: 3.5rem;
    line-height: 3em;
  }

  .tag {
    color: #fff;
    background-color: #019eaf;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    padding: 0.25em 0;
    width: 25px;
    border-radius: 48%;
    margin-left: 8px;
  }

  .btnP {
    color: #019eaf;
    border-color: #019eaf;
  }

  .btnP:hover {
    background-color: #019eaf;
    border-color: #019eaf;
    color: #fff;
  }

  .btnPhover {
    background-color: #019eaf;
    border-color: #019eaf;
    color: #fff;
  }

  // .cynBtn {
  //   border-color: rgb(23, 162, 184);
  //   color: rgb(23, 162, 184);
  // }

  // .cynBtn:hover {
  //   color: #fff;
  //   background-color: rgb(23, 162, 184);
  // }

  // .cynBtnHover {
  //   color: #fff;
  //   background-color: rgb(23, 162, 184);
  // }

  // .greenBtnHover {
  //   color: #fff;
  //   background-color: #28a745;
  // }
}
