.agenda .agendaBtn {
  background-color: #019eaf;
  border-color: #019eaf;
  color: #fff;
}
.agenda .mySession {
  color: #019eaf;
  border-color: #019eaf;
}
.tag {
  color: #fff;
  background-color: #019eaf;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  padding: 0.25em 0;
  width: 25px;
  border-radius: 48%;
  margin-left: 8px;
}
.agenda .mySession:hover {
  background-color: #019eaf;
  border-color: #019eaf;
  color: #fff;
}
@media (max-width: 768px) {
  /* .agenda{
    padding: 5rem 1rem 10rem;
  } */
  .fontMbs {
    font-size: 10px !important;
  }
  .tag {
    width: 18px;
    margin-left: 3px;
  }
  .antBtnRound {
    height: 32px !important;
    padding: 4px 12px !important;
    font-size: 10px !important;
  }
}
