.sendbird-modal {
  z-index: 3;
}
.watsongpt-contenter {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  box-shadow: -13px 0 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 7px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  height: 70vh;
  width: 65vh;
  max-width: 768px;

  .sendbird-openchannel-conversation-scroll {
    max-height: 30vh;
  }

  .sendbird-openchannel-conversation {
    display: none;
  }

  .sendbird-channel-list__header {
    display: none;
  }
  .sendbird-openchannel-conversation-header__right {
    display: none;
  }
  .MuiTypography-body2 {
    font-size: 14px !important;
    font-family: 'poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    line-height: 1em;
    letter-spacing: 0.01071em;
  }
  .MuiTypography-caption {
    font-size: 14px !important;
    font-family: 'poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    line-height: 1.5em;
    letter-spacing: 0.01071em;
  }
  .MuiGrid-justify-content-xs-space-between {
    justify-content: space-between;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAvatar-root {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 2rem;
    font-weight: 600;
    align-items: center;
    flex-shrink: 0;
    font-family: 'Manrope', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
  }
  .MuiAvatar-colorDefault {
    background-color: #ebfafa;
    color: #0010f7;
  }
  .customized-app {
    height: 100%;
  }
  .sendbird-chat-header__back__button {
    display: none;
  }
  .sendbird-channel-header__right-icon {
    display: none;
  }
  .sendbird-channel-header__title__right__user-id {
    display: none !important;
  }
  .sendbird-channel-headertitleright_name.sendbird-label {
    vertical-align: middle;
    display: block;
    line-height: 2;
  }
  .sendbird-channel-header .sendbird-channel-header__title {
    pointer-events: none;
  }
  .ce-chat-feed {
    height: 70vh !important;
    position: relative;
  }
  .ce-chat-list {
    height: 70vh !important;
    position: relative;
  }
  .ce-chat-feed {
    height: 70vh !important;
    position: relative;
  }

  .closeOutlined {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
  }

  // .ce-chat-engine {
  //   height: 100%;
  // }
  // .ce-wrapper {
  //   height: 100% !important;
  // }
  .ce-chat-list-column,
  .ce-settings-column {
    flex: 0 !important;
    max-width: 0 !important;
  }

  .ce-chat-feed-column {
    flex: 100% !important;
    max-width: 100% !important;
  }
  .chat-back {
    padding: 2rem 5rem;
    background-color: #eee;
    cursor: pointer;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
}

@media (max-width: 575px) {
  .watsongpt-contenter {
    // position: fixed;
    // left: 0px;
    // right: 0px;
    // bottom: 20px;
    width: 100%;
    left: 0;
    bottom: 0;
    max-height: 75vw;
    min-height: 75vh;
  }
  .sendbird-openchannel-conversation-scroll {
    max-height: 50vh !important;
  }

  .sendbird-app__conversation-wrap {
    display: none;
  }
  .sendbird-chat-header__back__button {
    // display: inline !important;
  }

  .chatlist-pc {
    display: none;
  }

  .watsongpt-contenter .closeOutlined {
    top: 30px;
    right: 30px;
    font-size: 5rem;
  }

  div#ce-feed-container {
    max-height: 54vh;
  }

  .ce-chat-settings-mobile-option {
    display: none;
  }

  .ce-chat-title-container {
    padding: 10px 10px;
  }

  .ce-chat-list-mobile-option {
    top: 15px;
  }

  .watsongpt-contenter .ce-chat-feed {
    height: 53vh !important;
    position: relative;
    width: 100vw !important;
    top: 0;
    margin-top: -70px;
  }

  div#msg-form-container {
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    width: 100vw !important;
  }

  .ce-chat-title {
    border-bottom: 1px solid #e0e6e9;
  }
  .ce-chat-title-container {
    border-bottom: 0 !important;
  }
  .customized-app {
    height: calc(100vh - 20px);
  }

  /* will be duplicated */
  .sendbird-channel-preview__avatar .sendbird-chat-header__avatar {
    width: 56px !important;
    height: 56px !important;
  }
  .sendbird-channel-preview__avatar
    .sendbird-chat-header__avatar
    .sendbird-image-renderer.sendbird-avatar-img {
    width: 56px !important;
    height: 56px !important;
  }
}
