/* 关于我们弹框 */
.contact-info-modal {
  .user {
    color: white;
    width: 8rem;
    height: 8rem;
  }
  .role {
    padding: 0.5 2rem;
    background-color: #EAFFF8;
    width: 80px;
    color: #00F7BF;
    border: 1px solid #00F7BF;
    border-radius: 7px;
  }
}
