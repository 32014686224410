.meet-our-team {
  background-color: #ffffff;
  border-radius: 7px;
  .cell {
    border-bottom: 1px solid #DFE6E9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
  }
  .cell-left {
    display: flex;
      flex-direction: row;
      align-items: center;
  }
    .yuan {
      border: 1px solid #B2BEC3;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }

  .right-btn {
    margin-right: 16px;
  }

  .avater {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #C4E2FC;
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    .cell {
        padding: 8px 16px;
    }
    .avater {
      min-width: 0;
      width: 0;
      height: 0;
      visibility: hidden;
      margin-right: 0;
    }
  }
}