#mobileInterface {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
  z-index: 11;
  top: auto;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#mobileInterface #joystickWrapper1 {
  pointer-events: auto;
  display: block;
  position: absolute;
  bottom: 200px;
  background-color: transparent;
  width: 120px;
  height: 120px;
  z-index: 12;
  touch-action: manipulation;
  background-color: rgba(0, 0, 0, 0);
}
#mobileInterface #joystickWrapper2 {
  pointer-events: auto;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  background-color: transparent;
  width: 50vw;
  height: 140px;
  z-index: 12;
  touch-action: manipulation;
  display: none;
}
#mobileInterface #joystickWrapper2 #jumpButton {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 1;
  touch-action: manipulation;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
#mobileInterface #joystickWrapper2 #jumpButton p {
  position: relative;
  display: inline-block;
  color: white;
  opacity: 1;
  margin: 0;
  padding: 0;
  letter-spacing: 4px;
  margin-left: 4px;
}

#mobileInterface1 {
  width: 100%;
  height: 500px;
  pointer-events: none;
  z-index: 11;
  top: auto;
  bottom: 100px;
  right: 0;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.frame {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  padding: 0px;
  font-size: 14px;
  border: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video1 {
  width: 0;
  height: auto;
}
