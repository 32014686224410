.LanguageView {
  .ant-dropdown-link {
    //margin-left: 4rem;
    color: #ffffff;
    font-weight: bold;

    .language {
      margin-left: 0.2rem;
    }
  }
}