.my-meetings-page {
  background-color: #ffffff;
  border-radius: 15px;
  .row {
    padding: 1rem 2rem 3rem 2rem;
    border-bottom: 1px solid #dee2e6 !important;
  }
  .row-title {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .type {
    padding: 0 1rem;
    text-align: center;
    background: #ffc107;
    border-radius: 2rem;
    color: #000000;
  }
  .user {
    color: white;
    width: 8rem;
    height: 8rem;
  }
  .contact-type {
    background: #6c757d;
    border-radius: 2rem;
    font-weight: bold;
    color: #ffffff;
  }
  .rejected {
    background: #dc3545;
    color: #ffffff;
  }
  .disable {
    cursor: not-allowed;
  }

  .organizer {
    width: 80px;
    color: #005009;
    border: 1px solid #005009;
    border-radius: 7px;
  }

  .exhibitor {
    width: 80px;
    color: #3DADEB;
    border: 1px solid #3DADEB;
    border-radius: 7px;
  }

  .visitor {
    width: 80px;
    color: #89C242;
    border: 1px solid #89C242;
    border-radius: 7px;
  }
  
    .yuan {
      border: 1px solid #B2BEC3;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  
    .oddRow {
      background: #ffffff
    }
  
    .evenRow {
      background: #F7FAFC
    }

    .opacity {
      opacity: 0.3;
    }

    .ant-table-thead>tr>th {
      background: none !important;
      padding: 14px 32px 32px;
  
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
    }
  .ant-table-tbody>tr>td {
    padding: 14px 32px;

    &:first-child {
      text-align: center;
      min-width: 120px;
      padding: 0;
    }

    &:nth-child(3) {
      min-width: 250px;
    }

    &:nth-child(4) {
      min-width: 200px;
    }

    &:nth-child(6) {
      min-width: 200px;
    }
  }
}
