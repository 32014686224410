.Detail-demand {
  .padd {
    padding: 6rem 0;
  }

  .gray {
    color: #6c757d;
  }

  .title-m {
    padding: 6rem 0;
  }

  .f50 {
    font-size: 5rem;
  }

  .agendaBtn {
    background-color: #019eaf;
    border-color: #019eaf;
    color: #fff;
    margin-bottom: 1.7rem;
  }

  .play {
    background-color: #28a745;
    border-color: #28a745;
  }

  .live {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .ended {
    background-color: #999;
    border-color: #999;
  }

  .removeBtn {
    color: #dc3545;
    border-color: #dc3545;
  }

  .removeBtn:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .noprofilepic {
    width: 12rem;
    border-radius: 6rem;
  }

  .img-des {
    margin-left: 5rem;
  }

  .line-clamp4 {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }

  .show {
    cursor: pointer;
    font-weight: 400;
    color: #019eaf;
    margin-top: 1rem;
    font-size: 2rem;
  }
}
