.help-desk {
  position: relative;
  .lobby {
    position: relative;
    background-color: #fff;
  }

  .bgImg {
    width: 100%;
  }

  .card {
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    margin-bottom: 40rem;
    padding: 4rem;
  }

  .card img {
    width: 8rem;
  }

  @media (max-width: 768px) {
    .card {
      margin-bottom: 4rem;
      padding: 4rem;
    }
  }

  .tooltipsterBox {
    min-width: 300px;
    max-width: 330px;
    pointer-events: auto;
    /* z-index: 9999999; */
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10rem;
    /* height: 148px; */
    width: 320px;
    box-sizing: border-box;
    position: absolute;
    background-color: #019eaf;
    border-radius: 20px;
    color: #fff;
    padding: 3rem;
    font-size: 15px;
  }

  .triangleDown {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #019eaf;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -15px;
  }
}
