.brochures-page {
  .ItemArea {
    // box-shadow: 0 0 1px #000000;
    margin-bottom: 1.6rem;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 8px;
  }
  .BtnArea {
    padding: 1rem 2rem;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    background-color: #019eaf;

    span {
      color: #ffffff;
    }
  }
  @media screen and (max-width: 767px) {
    .ItemArea {
      margin-top: 32px;
    }
  }
}
