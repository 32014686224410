.landing {
  position: relative;
  overflow: hidden;
}

.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.baseSize1 {
  width: 100% !important;
}

.baseSize {
  width: 100% !important;
  height: 100% !important;
}

.bgImg {
  /* position: absolute;
  left: 0;
  top: 0; */
  width: 100%;
  height: 100%;
}

.plane {
  position: absolute;
  width: 35%;
  top: 5%;
  right: 0;
  z-index: 10;
  animation: mymove 8s infinite;
  -webkit-animation: mymove 12s linear infinite;
  cursor: pointer;
  /*Safari and Chrome*/
}

.pedestrian {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* height: 20%; */
}

@keyframes mymove {
  from {
    left: 100%;
  }

  to {
    left: -60%;
  }
}

@-webkit-keyframes mymove

/*Safari and Chrome*/
  {
  from {
    left: 0px;
  }

  to {
    left: 200px;
  }
}

.entrelink1 {
  position: absolute;
  width: 13.5%;
  height: 18%;
  top: 44%;
  left: 0.8%;
}

.entrelink2 {
  position: absolute;
  width: 5.7%;
  height: 20%;
  top: 53%;
  left: 32%;
}

.entrelink3 {
  position: absolute;
  width: 6.5%;
  height: 20%;
  top: 55%;
  left: 43.8%;
}

.entrelink4 {
  position: absolute;
  width: 6.5%;
  height: 19%;
  top: 55%;
  left: 56.8%;
}

.entrelink5 {
  position: absolute;
  width: 5.5%;
  height: 19%;
  top: 52%;
  left: 68.8%;
}

.entrelink6 {
  position: absolute;
  width: 19.7%;
  height: 23%;
  top: 17.3%;
  left: 70.5%;
}

.timer {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  font-size: 12rem;
  height: 12rem;
  line-height: 1em;
  color: white;
  -webkit-box-reflect: below 10px 
  -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(255, 255, 255, 0.51)));
  text-shadow: 2px 2px 5px #000;
}
/* @keyframes go{ 
  0%{opacity:1;} 
  84%{opacity:1;transform:rotate(0deg) scale(1);} 
  100% {opacity:0;transform:rotate(360deg) scale(.01);} 
}

.timer span {
  animation:go 1s infinite;
  display: inline-block;
} */
.enterBtnArea {
  position: fixed;
  bottom: 100px;
  width: 100%;
}
.enterBtnIcon {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enterBtnIcon:hover {
  background-color: rgba(256, 256, 256, 0.8);
}
.enterBtnText {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
}