.BriefcaseItem {
  .noprofilepic {
    width: 10rem;
  }
  .clamp3 {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
  .mB40 {
    margin-bottom: 6rem;
  }
  .f40 {
    font-size: 4rem;
  }
    .list-content1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .recommend-product-img {
      width: 100%;
      height: 10rem;
      object-fit: contain;
    }
    .img-area {
      background-color: #FFFFFF;
      width: 100%;
      border-radius: 15px;
    }
    .recommend-bottom-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 40px;
      position: absolute;
      right: 30px;
    }
    .recommend-bottom-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 24px;
      margin-top: 16px;
      margin-bottom: 0;
    }
    .recommend-bottom-title span{
      word-break: break-word;
    }
    }
