.speakers-list {
  .cell {
    min-height: 45rem;
  }

  .profilePic {
    // width: 80%;
    // object-fit: cover;
    border-radius: 50%;
    // border-style: solid;
    // border-width: 1px 1px 1px 1px;
    // border-color: #f2f5f7;
    // vertical-align: middle;
    // cursor: pointer;
    // width: 100%;
    // height: 0px;/* padding will mage sizing here */
    // padding: 50% 0;/* make it a square = 100% vertical padding , none horizontal*/
    // background-image: url(http://dummyimage.com/750x1260/951&text=My_BG_Image);/* used a different color from the one in the tag */
    // background-size: 80% 80%;
    // /* reset just in case */
    // background-clip: border-box;
    // box-sizing:border-box;
    // background-repeat: round;
      width: 300px;
      // height: 300px;
      object-fit: cover;
  }

  .speakerName {
    color: #004096;
    font-weight: bold;
    font-size: 22px;
    margin-top: 2rem;
    cursor: pointer;
  }

  .speakerJobTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    word-wrap: break-word;
  }

    /**
  思路：
  	1.超出的文本隐藏
  	2.溢出用省略号显示
  	3.溢出不换行
  	4.将对象作为弹性伸缩盒子模型显示
  	5.从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
  	6.这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数
    */
    .text-two-line{
      // word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
}