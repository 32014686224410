.other-page {
  .You-May-Also-Like {
    margin-top: 6.7rem;
    font-family: "Poppins",sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .other-product-list {
    margin-top: 4rem;
  }

  .other-product-area {
    max-width: 143rem;
    display: flex;
    flex-direction: row;
  }

  .other-product-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    //border: solid 0.05rem #8c8b8b;
  }

  .arrow-left {
    object-fit: contain;
    //margin-right: 3.6rem;
  }

  .arrow-right {
    object-fit: contain;
    //margin-left: 3.6rem;
  }

  .other-product-title {
    width: 23.2rem;
    height: 6.2rem;
    font-family: "Poppins",sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 1.8rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .other-product-des {
    width: 23.2rem;
    font-family: "Poppins",sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
    margin-top: 1.8rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .selected-img {
    border: solid 0.1rem #d9000d;
  }
}
