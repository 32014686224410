.single-time-list {
  display: flex;
  flex-direction: row;
  .left-view {
    margin-top: 4rem;
  }
  .item {
    width: 18rem;
    height: 4rem;
    padding: 0 1rem;
    border: 1px solid #efefef;
  }
  .item-list {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    .cloumn {
      height: 4rem;
      padding: 0 1rem;
    }
    .cell {
        height: 4rem;
        border: 1px solid #efefef;
        padding: 0 1rem;
        .unclik-icon {
          width: 2rem;
          height: 2rem;
        }
      }
  }
}