.ChatMessage-chatroom-button {
  cursor: pointer;
  margin: auto;
  width: 48px;
  height: 48px;
  bottom: 40px;
  right: 40px;
  color: #fff;
  border-radius: 24px;
  text-align: center;
  // box-shadow: 2px 2px 3px #999;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.20);
  position: fixed !important;
  bottom: 9%;
  right: 3%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 45%;
    vertical-align: middle;
  }
}
