.WebinarType {
  .webinar-type {
    // color: #6c757d !important;
    // font-size: 5rem;
    // line-height: 3em;
    font-weight: bolder;
    font-size: 3.5rem;
    line-height: 3em;
  }

  .greenBtn {
    border-color: #28a745;
    color: #28a745;
  }

  .greenBtn:hover {
    color: #fff;
    background-color: #28a745;
  }

  .cynBtn {
    border-color: rgb(23, 162, 184);
    color: rgb(23, 162, 184);
  }

  .cynBtn:hover {
    color: #fff;
    background-color: rgb(23, 162, 184);
  }

  .cynBtnHover {
    color: #fff;
    background-color: rgb(23, 162, 184);
  }

  .greenBtnHover {
    color: #fff;
    background-color: #28a745;
  }
}