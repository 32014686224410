.my-profile {
  .pro-gray {
    color: #6c757d;
  }
  .pro-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 8rem;
  }
  .pro-btn {
    background-color: #019eaf;
    border-color: #019eaf;
    color: #fff;
  }
  .noprofilepic {
    width: 25rem;
  }
  .pro-title {
    font-size: 3rem;
    color: #000000;
    font-weight: bold;
    margin: 2.5rem 0;
    text-align: center;
  }
  .btn-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #495057;
    font-size: 15px;
  }

  .ant-tabs-tab-btn {
    color: #999999;
    font-size: 15px;
  }

  .ant-tabs-ink-bar {
    background-color: #019eaf;
    height: 4px !important;
  }

  .pro-text-secondary {
    color: #6c757d !important;
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  .pro-password-policy {
    padding: 3rem 5rem 0;

    .pro-ul {
      padding-left: 1.5rem;

      li {
        list-style-type: disc;
        line-height: 2em;
      }
    }
  }
  .conent-area {
    background-color: #fff;
    padding: 2rem;
    border-radius: 4px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
    position: absolute;
    top: 4.8rem;
  }

  .organizer {
    width: 80px;
    color: #005009;
    border: 1px solid #005009;
    border-radius: 7px;
  }

  .exhibitor {
    width: 80px;
    color: #3dadeb;
    border: 1px solid #3dadeb;
    border-radius: 7px;
  }

  .visitor {
    width: 80px;
    color: #89c242;
    border: 1px solid #89c242;
    border-radius: 7px;
  }
}
