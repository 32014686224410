.my-availability-page {
  background-color: #ffffff;
  border-radius: 15px;
  .btn-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
  }
  .btn-area {
    padding: 0.5rem 2rem;
    border: 0.1rem solid #019eaf;
    margin-right: 4rem;
    border-radius: 2rem;
    span {
      font-size: 1.8rem;
      color: #019eaf;
    }
  }
  .active {
    background: #019eaf;
    span {
      font-size: 1.8rem;
      color: white;
    }
  }
  .line {
    width: 12rem;
    height: 0.35rem;
    margin-top: 1rem;
  }
  .dian {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #2300f1;
  }
  .bg-2 {
    background-color: #ca00fb;
  }
  .bg-3 {
    background-color: #00f6b8;
  }
}
