.game {
  .game-img {
    width: 100px;
    height: 100px;  
  }
  .modal-view {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    background-color: rgba(45, 52, 54, .4);
  }
  .modal-content-view {
    position: absolute;
    width: 416px;
    height: 450px;
    background-color: #fff;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12),
        0 6px 16px 0 rgba(0, 0, 0, .08),
        0 9px 28px 8px rgba(0, 0, 0, .05)
  }
  .icon {
    width: 182px;
    height: 182px;
    margin-bottom: 20px;
  }
  .score {
    color: '#0010F7';
  }
  .game-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .game-des {
    color: '#636E72';
    font-size: 14px;
    text-align: center;
  }
  a {
     text-decoration: underline;
  }
  .buttom-btn {
    width: 352px;
    height: 40px;
    margin-top: 30px;
    border: 1px solid #004096;
  }

  @media screen and (max-width: 768px) {
    .game-img {
      width: 60px;
      height: 60px;  
    }
    .modal-content-view {
        position: absolute;
        width: 80%;
        height: 250px;
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        left: 10%;
        // top: 10%;
      }
      .icon {
        width: 48px;
        height: 48px;
        margin-bottom: 2rem;
      }
      .game-title {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
      .game-des {
        color: '#636E72';
        font-size: 12px;
        text-align: center;
      }
      .buttom-btn {
        width: 80%;
        height: 40px;
        margin-top: 10px;
        border: 1px solid #004096;
      }
  }
}